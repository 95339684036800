// consumer_flight_search_returnStipulatesRule
const __request = require(`./__request/__request_contentType_json`);

const consumer_flight_search_returnStipulatesRule = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/flight/search/returnStipulatesRule',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_flight_search_returnStipulatesRule;
